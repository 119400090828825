<template>
  <div class="application">
    <Navigation />
    <main>
      <ul>
        <li>
          <div></div>
        </li>
        <li>
          <p>合作申请</p>
        </li>
        <li>
          <div></div>
        </li>
      </ul>
      <div class="updata">
        <div class="download">
          <div class="download-box">
            <p class="p1">合作申请表</p>
            <p class="p2">专业的课程体系、全方位的教师培训和选拔流程、一体化的服务支持、刚需蓝海赛道！如果你对此项目感兴趣，请填写合作申请表，获取更全面解答！</p>
          </div>
          <a href="./合作申请表.docx" download=''>
            <div class="btn">下载附件</div>
          </a>
        </div>
        <div class="upload">
          <i class="el-icon-upload"></i>
          <p class="p1">上传合作申请表</p>
          <p class="p2">填写完合作申请表后请<br />点此上传</p>
          <el-upload class="upload-demo" drag action="http://einsteinapi.zbfib.com//api/common/upload" multiple
            :on-success='supload'>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload>
        </div>
      </div>
    </main>
    <consulting />
    <foot />
  </div>
</template>



<script>
import Navigation from "@/components/header.vue";
import footer from "@/components/footer.vue";
import consulting from "@/components/consulting.vue";
export default {
  components: {
    Navigation,
    foot: footer,
    consulting,
  },
  methods: {
    supload(response, file) {
      console.log(response);
      if (response.code == 1) {
        const h = this.$createElement;
        this.$notify({
          title: "提示",
          message: h("i", { style: "color: teal" }, response.msg),
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.application {
  main {
    padding: 1rem 3rem;
    max-width: 100%;
    ul {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      li {
        p {
          font-size: 0.24rem;
        }
        div {
          height: 1px;
          background: #ccc;
        }
      }
      li:nth-child(1) {
        width: 40%;
        div {
          width: 100%;
        }
      }
      li:nth-child(3) {
        width: 40%;
        div {
          width: 100%;
        }
      }
    }
    .updata {
      padding-top: 1rem;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .download {
        width: 45%;
        .download-box {
          height: 4rem;
          border: 4px solid #5ac6ec;
          border-radius: 0.2rem;
          padding-top: 0.6rem;
          .p1 {
            font-size: 0.22rem;
            text-align: center;
            font-weight: bold;
          }
          .p2 {
            color: #707070;
            font-size: 0.2rem;
            width: 92%;
            margin: 0.6rem auto 0 auto;
            text-indent: 0.4rem;
            text-align: left;
            line-height: 180%;
          }
        }
        .btn {
          height: 0.53rem;
          width: 2.8rem;
          background: #5ac6ec;
          border-radius: 0.22rem;
          margin: auto;
          margin-top: 0.3rem;
          color: #fff;
          font-size: 0.3rem;
          text-align: center;
          line-height: 0.53rem;
        }
      }
      .upload {
        width: 45%;
        height: 4rem;
        border: 4px solid #000;
        border-radius: 0.2rem;
        padding-top: 0.6rem;
        cursor: pointer;
        position: relative;
        i {
          font-size: 1.2rem;
          color: #707070;
          display: block;
          text-align: center;
        }
        .p1 {
          font-size: 0.3rem;
          color: #404040;
          text-align: center;
        }
        .p2 {
          color: #aaa;
          font-size: 0.2rem;
          text-align: center;
        }
        .upload-demo {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          opacity: 0;
          /deep/.el-upload {
            width: 100%;
            height: 100%;
            .el-upload-dragger {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  @media (max-width: 750px) {
    main {
      padding: 10px;
      .updata {
        .download {
          width: 80%;
          margin: auto;
        }
        .upload {
          width: 80%;
          margin: auto;
          margin-top: 40px;
        }
      }
    }
  }
}
</style>